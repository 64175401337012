import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: '#1d4164',
      height: '100%',
    },
    loadingIndicator: {
      flex: 1,
    },
  })
);

interface ErrorScreenProps {
  meetingRoomName: string;
}

export default function ErrorScreen({ meetingRoomName }: ErrorScreenProps) {
  const classes = useStyles();

  const [schoolName, setSchoolName] = useState('');
  const [schoolAbbreviation, setSchoolAbbreviation] = useState('');

  useEffect(() => {
    if (meetingRoomName !== undefined && meetingRoomName !== null) {
      fetch(`${process.env.REACT_APP_MEETING_ROOM_ENDPOINT}/schoolForMeetingRoom/${meetingRoomName}`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => ({ name: data.name, abbreviation: data.abbreviation }))
        .then(data => {
          setSchoolName(data.name);
          setSchoolAbbreviation(data.abbreviation);
        });
    }
  }, [meetingRoomName]);

  const linkToOptisComponent =
    schoolName !== undefined && schoolName !== null ? (
      <p>
        <b>If you followed a link from the email copy of your schedule it's possible that link has expired. </b>
        Please log in to OPTIS by going to{' '}
        <a href={'http://www.parentinterviews.com/' + schoolAbbreviation}>
          www.parentinterviews.com/{schoolAbbreviation}
        </a>{' '}
        and click the <b>Join Meeting</b> button directly from your schedule there.
      </p>
    ) : null;

  return (
    <div className={clsx(classes.container)}>
      <div className={clsx(classes.loadingIndicator)}>
        <h3>Something Went Wrong...</h3>
        <p>Sorry, it seems like there was an error loading your meeting.</p>
        {linkToOptisComponent}
        <p>
          If the error persists please contact us at{' '}
          <a href="mailto: support@parentinterviews.com">support@parentinterviews.com</a> or give us a call at
          709.746.5399
        </p>
      </div>
    </div>
  );
}
